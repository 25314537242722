<template>
    <div class="permission-page">
        <router-link class="back-previous-screen" to="/business/settings/business-settings/permission-groups">
            {{ "< " + $t("Back to [Previous Screen]", { previousScreen: $t("Business Settings") }) }}
        </router-link>

        <CustomLoader v-if="loading" height="400px" />

        <template v-else>
            <BusinessPermissionGroupForm
                class="mb-7"
                ref="permissionGroupForm"
                :title="$t('Create Permission Group')"
                :permission-data="permissionData"
                :submitted="submitted"
                :product-areas-list="businessProductAreasList"
                :countries-list="businessCountriesList"
                :banks-list="businessBankList"
                @onFormChange="onFormChange"
            />

            <PermissionGroupUsers
                ref="permissionGroupUsers"
                :users-data-list="businessUsersData"
                :selected-users="permissionData.users"
                @onFormChange="onUsersFormChange"
            />

            <div class="permission-page__footer">
                <button class="main-btn-outline btn" @click="handleCancel">{{ $t('Cancel') }}</button>

                <button
                    :data-kt-indicator="submitLoading ? 'on' : 'off'"
                    :disabled="submitLoading || !isFormChanged"
                    class="btn main-btn"
                    @click="handleSave">
                    <span class="indicator-label"> {{ $t("Save") }} </span>

                    <span class="indicator-progress">
                        {{ $t("pleaseWait") }}
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
            </div>
        </template>
    </div>
</template>

<script>
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { BusinessUserPermissionTypeEnum } from "@/store/enums/business/BusinessUserPermissionTypeEnum";
import store from "@/store";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader.vue";
import BusinessPermissionGroupForm from "@/buying-teams/pages/business/settings/business/components/BusinessPermissionGroupForm.vue";
import NotificationService from "@/buying-teams/services/NotificationService";
import PermissionGroupUsers from "@/buying-teams/pages/bank/settings/bank/components/permission/PermissionGroupUsers.vue";
import { filterAllowedBanks } from "@/store/models/business/permissions/BusinessPermissionGroup";

export default {
    name: "BusinessEditPermissionGroup",
    components: {
        PermissionGroupUsers,
        BusinessPermissionGroupForm,
        CustomLoader
    },
    computed: {
        currentUser() {
            return store.getters.currentUser;
        },
        businessBankList() {
            return store.getters.businessBanks;
        },
        businessCountriesList() {
            return store.getters.businessCountries
        },
        businessProductAreasList() {
            return store.getters.businessProductAreas
        },
        isFormChanged() {
            return JSON.stringify(this.permissionGroupForm) !== this.permissionGroupFormInitData;
        },
        businessBanksListIds() {
            return (this.businessBankList || []).map(c => c.id);
        }
    },
    data() {
        return {
            groupId: null,
            loading: true,
            permissionData: null,
            submitted: false,
            submitLoading: false,
            usersDataLoading: true,
            businessUsersData: [],
            permissionGroupForm: null,
            permissionGroupFormInitData: null,
        }
    },
    beforeMount() {
        if (this.$route.params.id) {
            this.groupId = Number(this.$route.params.id);
        } else {
            this.$router.push({ name: 'business-settings-permission-groups' });
        }
    },
    mounted() {
        if (this.currentUser && !this.currentUser.can(BusinessUserPermissionTypeEnum.CHANGE_USER_GROUP_PERMISSIONS)) {
            this.$router.push('/business/settings/business-settings/overview');
        }

        setCurrentPageBreadcrumbs({ title: `${this.$t("hi")} ${this.currentUser.first_name},  <span>${this.$t("welcomeBack")}</span>` });

        Promise.all([
            this.handleGetUsersList(),
            this.getPermissionsGroup(),
        ]).finally(() => {
            this.loading = false;
        })
    },
    methods: {
        async getPermissionsGroup() {
            await store.dispatch('getBusinessPermissionsGroupById', this.groupId).then(res => {
                this.permissionData = {
                    group_name: res.name,
                    permissions: res.functionalPermissions,
                    countries: res.allowedCountries,
                    product_areas: res.allowedProductAreas,
                    banks: filterAllowedBanks(res.blockedBanks, this.businessBanksListIds),
                    users: res.includedUsers,
                };
                this.permissionGroupFormInitData = JSON.stringify(this.permissionData);
            })
        },
        async handleGetUsersList() {
            await store.dispatch('getPermissionGroupUsersList').then(res => {
                this.businessUsersData = res;
            })
        },
        handleCancel() {
            this.$router.push({ name: 'business-settings-permission-groups' });
        },
        onFormChange(newForm) {
            if (this.permissionGroupForm && Object.keys(this.permissionGroupForm).length) {
                Object.keys(newForm).forEach(key => {
                    this.permissionGroupForm[key] = newForm[key];
                });
            } else {
                this.permissionGroupForm = { ...newForm };
            }
        },
        onUsersFormChange(users) {
            if (this.permissionGroupForm && Object.keys(this.permissionGroupForm).length) {
                this.permissionGroupForm.users = users;
            }
        },
        handleSave() {
            const permissionGroupForm = this.$refs.permissionGroupForm;
            this.submitted = true;

            if (permissionGroupForm.isFormValid) {
                this.submitLoading = true;
                const payload = { ...this.permissionGroupForm, group_id: this.groupId };
                payload.banks = filterAllowedBanks(permissionGroupForm.formData.banks, this.businessBanksListIds);

                store.dispatch('editBusinessPermissionsGroup', payload)
                    .then(() => {
                        NotificationService.swalNotify({ confirmButtonText: this.$t("OK") });
                        this.$router.push({ name: 'business-settings-permission-groups' });
                    })
                    .finally(() => {
                        this.submitLoading = false;
                    });
            } else {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth", });
            }
        }
    }
}
</script>

<style lang="scss">
.permission-page {
    &__footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: 34px;
        padding-right: 40px;
        padding-bottom: 5px;
    }
}
</style>
