<template>
    <div class="permission-group-users">
        <div class="permission-group-users__header">
            <div class="users-header-title">
                <h4>{{ $t('Users') }}</h4>
                <span v-if="addedUsers.length">{{ addedUsers.length }}</span>
            </div>
            <div class="users-header-right" v-if="addedUsers.length">
                <div class="search-input">
                    <img src="/media/buying/icons/search-black.svg" alt="">
                    <el-input v-model="search" :placeholder="$t('Search User')"></el-input>
                </div>
                <button @click="handleOpenModal" class="btn main-btn">
                    {{ $t('Add User') }}
                </button>
            </div>
        </div>

        <div class="users-data-table" v-if="addedUsers.length">
            <el-table :data="displayData">
                <el-table-column v-slot="{ row }" prop="name" :label="$t('Name')">
                    <div class="">
                        <img :src="row.icon_path" :alt="row.name" class="me-4">
                        <span>{{ row.name }}</span>
                    </div>
                </el-table-column>
                <el-table-column prop="job_title" :label="$t('Job Title')" width="244"></el-table-column>
                <el-table-column prop="role" :label="$t('Role')" width="120"></el-table-column>
                <el-table-column :label="$t('Action')" width="94" v-slot="{ row }">
                    <button class="delete-user" @click="handleDeleteUser(row.id)">
                        <inline-svg src="/media/buying/icons/delete-icon.svg" />
                    </button>
                </el-table-column>
            </el-table>

            <CustomPagination
              class="mt-4 mb-4 ms-7"
              :current-page="page"
              :total="getFilteredData.length"
              :page-size="pageSize"
              :hide-on-single-page="false"
              @currentPageChange="page = $event"
              @pageSizeChange="pageSize = $event"
            />
        </div>

        <div v-else class="users-no-data">
            <img src="/media/buying/icons/empty-state-users.svg.svg" alt="">
            <h2>{{ $t('No Users Selected') }}</h2>
            <p>{{ $t("Choose users using the 'Add Users' button.") }}</p>
            <button class="btn main-btn" @click="handleOpenModal">
                <inline-svg width="12" height="12" src="/media/buying/icons/plus.svg" />
                <span>{{ $t('Add Users') }}</span>
            </button>
        </div>
    </div>

    <SelectionModalTable
      v-if="dialogVisible"
      :dialog-visible="dialogVisible"
      :options="usersDataList"
      :modal-title="$t('Add User')"
      :modal-subtitle="$t('Select the users to whom you want to assign the Permission Group.')"
      :additional-columns="customersTableColumns"
      :modelValue="addedUsersIds"
      @handleCloseModal="handleCloseModal"
      @handleConfirm="handleConfirm"
    />
</template>

<script>
import CustomPagination from "@/buying-teams/shared-components/utils/CustomPagination.vue";
import SelectionModalTable from "@/buying-teams/shared-components/settings/SelectionModalTable.vue";

export default {
    name: "PermissionGroupUsers",
    components: {
        SelectionModalTable,
        CustomPagination
    },
    props: {
        usersDataList: Array,
        selectedUsers: Array,
    },
    emits: ['onFormChange'],
    data() {
        return {
            search: '',
            page: 1,
            pageSize: 5,
            addedUsers: [],
            addedUsersIds: [],
            dialogVisible: false,
        }
    },
    computed: {
        displayData() {
            return this.getFilteredData.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
        },
        getFilteredData() {
            let data = this.addedUsers;
            return this.search ? data.filter((item) => {
                return item.name.toLowerCase().includes(this.search.toLowerCase())
            }) : data;
        },
        customersTableColumns() {
            return [
                {
                    prop: 'job_title',
                    label: this.$t('Job Title'),
                    minWidth: '150px'
                },
                {
                    prop: 'role',
                    label: this.$t('Role'),
                    minWidth: '150px'
                },
            ]
        },
        selectedUsersIds() {
            return this.addedUsers.map(u => u.id);
        }
    },
    methods: {
        handleCloseModal() {
            this.dialogVisible = false;
        },
        handleOpenModal() {
            this.dialogVisible = true;
        },
        handleConfirm(value) {
            const newUsers = [];
            this.addedUsersIds = value;
            this.usersDataList.forEach(user => {
                if (this.addedUsersIds.includes(user.id)) {
                    newUsers.push(user);
                }
            });
            this.addedUsers = newUsers;
            this.dialogVisible = false;
        },
        setSelectedUsersData() {
            if (this.selectedUsers && this.selectedUsers.length) {
                this.addedUsersIds = this.selectedUsers;
                this.addedUsers = this.usersDataList ? this.usersDataList.filter(u => this.addedUsersIds.includes(u.id)) : [];
            }
        },
        handleDeleteUser(id) {
            this.addedUsersIds = this.addedUsersIds.filter(u => u !== id);
            this.addedUsers = this.addedUsers.filter(u => u.id !== id);
        }
    },
    mounted() {
        this.setSelectedUsersData();
    },
    watch: {
        selectedUsersIds: {
            deep: true,
            handler(newVal) {
                this.$emit('onFormChange', newVal);
            }
        }
    }
}
</script>

<style lang="scss">
.permission-group-users {
    border-radius: 14px;
    background: #FFF;
    box-shadow: 0px 9px 29px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 24px 32px;

        .users-header-title {
            display: flex;
            align-items: center;
            h4 {
                color: #434343;
                font-size: 18px;
                font-weight: 700;
                line-height: 22px;
                margin-bottom: 0;
                margin-right: 8px;
            }
            span {
                border-radius: 3px;
                background: #ECECEC;
                color: #434343;
                font-size: 12px;
                font-weight: 600;
                line-height: 15px;
                padding: 2px 3px;
            }
        }

        .users-header-right {
            display: flex;
            align-items: center;
            gap: 8px;
            .search-input {
                position: relative;
                img {
                    position: absolute;
                    left: 26px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 12px;
                    filter: invert(58%) sepia(5%) saturate(20%) hue-rotate(12deg) brightness(93%) contrast(94%);
                }
                .el-input__inner {
                    border: 1px solid #E5E5E5;
                    border-radius: 26px;
                    background: transparent;
                    padding-left: 47px;
                    height: 43px;
                    color: #2B2B2B;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;

                    &::placeholder {
                        color: #8B8B8B;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17px;
                    }

                    @media (min-width: 400px) {
                        width: 260px;
                    }
                }
            }
        }
    }

    .users-data-table {
        border-top: 1px solid #EBEEF5;

        .delete-user {
            border: none;
            background: transparent;
            width: 24px;
            height: 24px;
            padding: 0;
            svg path {
                fill: #E22D21;
            }
        }

        .el-table th.el-table__cell {
            padding: 0;
            height: 74px;
            background: #FAFAFA;
        }
        .el-table .el-table__cell {
            padding: 0;
            height: 74px;
        }
        .el-table {
            tr {
                background: #FAFAFA;
            }
            td, th {
                &:first-child .cell {
                    padding-left: 32px;
                }
            }
            td, th {
                &:last-child .cell {
                    padding-right: 32px;
                    text-align: center;
                }
            }
        }
        .el-table__body-wrapper {
            tr {
                background: #fff;
            }
        }
        .el-table__header {
            tr th .cell {
                color: #000;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
            }
        }
        .el-table td.el-table__cell {
            background: #fff;
            &:nth-child(1) div {
                color: #2B2B2B;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
            }
            div {
                color: #8B8B8B;
                font-size: 14px;
                font-weight: 600;
                line-height: 23px;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }

    .users-no-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 100px;
        margin-bottom: 100px;
        img {
            margin-bottom: 20px;
        }
        h2 {
            color: #D4D4D4;
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
            margin-bottom: 7px;
        }
        p {
            color: #D4D4D4;
            font-size: 12px;
            font-weight: 400;
            line-height: 21.36px;
            margin-bottom: 40px;
        }
        button.btn {
            background: #F6F7FE !important;
            padding: 12px 36px !important;
            display: flex;
            align-items: center;
            gap: 10px;
            transition: .3s;
            span {
                color: #435BF4;
                font-size: 14px;
                font-weight: 700;
                line-height: 17px;
            }
            &:hover {
                opacity: 0.8;
            }
        }
    }
}
</style>
